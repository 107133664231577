export const SUPABASE_KEYS = {
  CONTRACTS: 'airtable_contracts',
  CUSTOMER_ASSETS: 'airtable_customer_assets',
  CUSTOMER_LOCATIONS: 'airtable_customer_locations',
  TICKETS: 'hubspot_tickets',
  COMPANIES: 'companies',
  USERS: 'users',
  SPARES: 'airtables_spares',
  SPARES_LOCATION: 'airtable_fsls',
};




