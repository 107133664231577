import { User } from '@/types';
import { create } from 'zustand';

export interface UserData {
  id: any;
  created_at: string;
  company: string;
  role: string | null;
  name: string;
  firstname: string | null;
  lastname: string | null;
  profile_image: string;
  email: string;
}
export type SortType = {
  id: string;
  desc: boolean;
};

export type TableDataStore = {
  searchQuery: string;
  currentPage: number;
  filterValue: string;
  sortBy: SortType[];
  selectLocationId?: string[];
  selectLabel?: string[];
};

type SetFunction<T> = (updater: (state: T) => T) => void;

const equipmentStore = (set: SetFunction<TableDataStore>) => ({
  searchQuery: '',
  currentPage: 1,
  filterValue: 'All',
  sortBy: [],
  selectLocationId: [],
  selectLabel: [],

  setEquipment: (resource: Partial<TableDataStore>) => set((state: TableDataStore) => ({ ...state, ...resource })),
});

export const useEquipmentStore = create<TableDataStore & { setEquipment: (state: Partial<TableDataStore>) => void }>(
  equipmentStore,
);

const contractStore = (set: SetFunction<TableDataStore>) => ({
  searchQuery: '',
  currentPage: 1,
  filterValue: 'All',
  sortBy: [],
  setContract: (resource: Partial<TableDataStore>) => set((state: TableDataStore) => ({ ...state, ...resource })),
});

export const useContractStore = create<TableDataStore & { setContract: (state: Partial<TableDataStore>) => void }>(
  contractStore,
);

const ticketStore = (set: SetFunction<TableDataStore>) => ({
  searchQuery: '',
  currentPage: 1,
  filterValue: '',
  sortBy: [],
  setTicket: (resource: Partial<TableDataStore>) => set((state: TableDataStore) => ({ ...state, ...resource })),
});

export const useTicketStore = create<TableDataStore & { setTicket: (state: Partial<TableDataStore>) => void }>(
  ticketStore,
);

const userInitialState: User = {
  account_id: '',
  id: '',
  approved: false,
  company: '',
  created_at: null,
  email: '',
  name: null,
  firstname: null,
  lastname: null,
  profile_image: '',
  role: null,
};

const userStore = (set: SetFunction<User | null>) => ({
  ...userInitialState,
  setUser: (resource: User) => set((state: User) => ({ ...state, ...resource })),
  resetUser: () => set(() => userInitialState),
});

export const useUserStore = create<User & { setUser: (state: User) => void; resetUser: () => void }>(userStore);

type SupportProduct = {
  isSupportProduct: boolean;
};

const supportProductStore = (set: SetFunction<SupportProduct>) => ({
  isSupportProduct: false,
  setIsSupportProduct: (resource: SupportProduct) => set((state: SupportProduct) => ({ ...state, ...resource })),
});

export const useSupportStore = create<SupportProduct & { setIsSupportProduct: (state: SupportProduct) => void }>(
  supportProductStore,
);
