'use client';

import { queryClient } from '@/config/query-client';
import { MantineProvider, ColorSchemeScript, createTheme } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import React, { useRef, useEffect, useCallback } from 'react';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/spotlight/styles.css';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '../styles/global.css';
import UserProvider from '@/components/auth/UserProvider';

const RootLayout = ({ children }) => {
  const pathname = usePathname();
  const previousValueRef = useRef<any>();

  const handlePathnameChange = useCallback(() => {
    if (previousValueRef.current === '/app/help' && pathname !== '/app/help') {
      window.location.reload();
    }
    previousValueRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    handlePathnameChange();
  }, [handlePathnameChange]);

  const theme = createTheme({
    primaryColor: 'pivit',
    primaryShade: 6,
    colors: {
      pivit: [
        '#ffeee4',
        '#ffdbcf',
        '#fbb8a0',
        '#f6926d',
        '#f27042',
        '#f05c27',
        '#f05018',
        '#d6400a',
        '#c03806',
        '#a72d00',
      ],
    },
    defaultRadius: 'md',
    components: {
      Modal: {
        defaultProps: {
          centered: true,
          overlayProps: {
            backgroundOpacity: 0.55,
            blur: 3,
          },
        },
      },
    },
  });

  return (
    <html lang="en">
      <head>
        <title>OneHub</title>
        <Script src="https://api.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.js" />
        {/* add favicon */}
        <link rel="icon" href="/favicon.ico" />
        {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400;500;600;700&display=swap;"
          rel="stylesheet"
        ></link> */}
        <ColorSchemeScript />
      </head>
      {pathname !== '/app/help' && (
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5087436.js"></script>
      )}
      <body>
        <QueryClientProvider client={queryClient}>
          <MantineProvider theme={theme}>
            <ModalsProvider>
              <Notifications />

              <UserProvider>{children}</UserProvider>
            </ModalsProvider>
          </MantineProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </body>
    </html>
  );
};

export default RootLayout;
