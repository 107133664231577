export const QUERIES = {
  ASSETS: (pageIndex: number, id: string) => ['assets', pageIndex, id],
  ASSET: (id: string) => ['asset', id],
  ASSETAUTOCOMPLETE: (search: string) => ['asset-serial', search],
  CONTRACTS: (search: string, pageIndex: number, filterVariable: string, sortBy: any[]) => [
    'contracts',
    search,
    pageIndex,
    filterVariable,
    sortBy,
  ],
  CONTRACT: (id: string) => ['contract', id],
  CONTRACT_HISTORY: ['contract-histoy'],

  CHART_SUMMARY: ['chart-summary'],
  COMPANIES: ['companies'],
  EQUIPMENT: (search: string, pageIndex: number, filterVariable: string, sortBy: any[], select: string[]) => [
    'equipment',
    search,
    pageIndex,
    filterVariable,
    sortBy,
    select,
  ],
  EQUIPMENT_LOCATIONS: (search: string) => ['equipment-location', search],
  GLOBAL_SEARCH: query => ['global-search', query],
  HOSTNAME: (id: string[]) => ['hostname', id],
  LOCATION: (id: string) => ['location', id],
  INVITED: ['invited-user'],
  LOCATIONS: ['assets-locations'],

  MEMBERS: (id?: string, search?: string) => ['members', id, search],

  SESSION: ['session'],
  SPARE: ['spare'],
  SPARES: (id: string) => ['spare', id],
  SPARE_LOCATION: (code: string) => ['spare-location', code],
  TICKETS: (search: string, pageIndex: number, filterVariable: string, sortBy: any[]) => [
    'tickets',
    search,
    pageIndex,
    filterVariable,
    sortBy,
  ],
  TICKET_COUNT: ['ticket-count'],
  TICKET: (id: string) => ['ticket', id],
  TICKET_HISTORY: ['ticket-history'],
  TICKET_FILES: (userId: string, ticketId: string, fileId?: string, searchQuery?: string) => [
    'ticket-files',
    userId,
    ticketId,
    fileId,
    searchQuery,
  ],
  UPDATES: (id: string) => ['two-hub-updates', id],
  USERS: (searchQuery: string, pageIndex: number) => ['users', searchQuery, pageIndex],
  LOGGED_USER: (id: string) => ['logged-user', id],
  SESSION_USER_ID: ['session-user-id'],
};
