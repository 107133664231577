'use client';

import { useLoggedUser, useUserId } from '@/hooks/users';
import { useUserStore } from '@/store/store';
import { usePathname } from 'next/navigation';
import React from 'react';

const UserProvider = ({ children }) => {
  const user = useUserStore();
  const { data: id, refetch } = useUserId();
  const { data: loggedUser } = useLoggedUser(id, {
    enabled: !!id,
  });

  const pathname = usePathname();
  React.useEffect(() => {
    if (pathname.includes('/app') && !id) {
      refetch();
    }

    if (id && loggedUser) {
      user.setUser(loggedUser);
    }
  }, [user.id, id, loggedUser, pathname]);

  return <>{children}</>;
};

export default UserProvider;
