import { getCompanyMembers, getUserId, getProfile, getLoggedUser, UserResponseType, getInvitedUser } from '@/api/users';
import { QUERIES } from '@/config/query-keys';
import { CompanyMembers, User } from '@/types';
import { PostgrestError, UserResponse } from '@supabase/supabase-js';
import { useQuery } from '@tanstack/react-query';

export function useUsers(id: string, searchQuery?: string, pageIndex?: number) {
  return useQuery<UserResponseType, PostgrestError>({
    queryKey: QUERIES.USERS(searchQuery, pageIndex),
    queryFn: () => getProfile(id, searchQuery, pageIndex),
  });
}
export function useLoggedUser(id: string, options: any) {
  return useQuery<User, PostgrestError>({
    queryKey: QUERIES.LOGGED_USER(id),
    queryFn: () => getLoggedUser(id),
    ...options,
  });
}

export function useUserId() {
  return useQuery({
    queryKey: QUERIES.SESSION_USER_ID,
    queryFn: () => getUserId(),
  });
}

export function useCompanyMembers(id: string, search: string, options?: any) {
  return useQuery<CompanyMembers[], PostgrestError>({
    queryKey: QUERIES.MEMBERS(id, search),
    queryFn: () => getCompanyMembers(id, search),
    ...options,
  });
}

export function useInvitedUser(options?: any) {
  return useQuery<UserResponse>({
    queryKey: QUERIES.SESSION,
    queryFn: () => getInvitedUser(),
    ...options,
  });
}
